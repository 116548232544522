const config = {
  TOKEN_CALENDLY:
    "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjc4MDk3OTI0LCJqdGkiOiI2ODI0ODdlZi02YjZlLTQ1ZGUtOTIxZS00NWM2MjI4MTBkZmQiLCJ1c2VyX3V1aWQiOiJDQkFEM0tHWUhHWUZTT1pWIn0.Nxli4qjaAvgcXoyi4MNpIlkqgt47xAJovVn18rvFAFlCndKhCJePLL35_BiP7pcPT74rtMswrnQSw-Ki31-kdA",
  CALENDLY_URL: "https://calendly.com/suzie-krawczyk",
  TOKEN_EMAIL: "cace142a-1087-424f-aef1-4eb5d2b15650",
  SUBJECT_EMAIL: "VTO Map Implementation form"
}

const scriptLink =
  "https://storage.googleapis.com/make-an-appointment/static/js/main.0147df6e.js"
const cssLink =
  "https://storage.googleapis.com/make-an-appointment/static/css/main.34f3e680.css"

export { config, scriptLink, cssLink }
