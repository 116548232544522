import React from "react"
import Header from "../landing/header"

const MakeAnAppointment = ({lang}) => {

  return (
    <div>
      <Header  lang={lang}/>
      <div id="make-an-appointment" style={{ marginTop: "100px" }}></div>
    </div>
  )
}

export default MakeAnAppointment
