import * as React from "react"

import Seo from "../components/seo"
import { organizationSchema } from "../const/schemas"
import MakeAnAppointment from "../components/makeAnAppointment/makeAnAppointment"
import { config, scriptLink, cssLink } from "../const/makeAnAppointmentConfig"
import "../assets/css/style.css"
import "../assets/css/layout.css"
import { languages } from "../const/languages"

const MakeAnAppointmentPage = () => <MakeAnAppointment lang={languages.EN} />

export const Head = () => {
  React.useEffect(() => {
    window.env = config
  }, [])

  return (
    <>
      <Seo
        title="AR-Labs.io | VTO Map implementation"
        description="It's time for your business to enter augmented reality! Fill the form to make an appointment."
        canonicalURL="https://ar-labs.io/make-an-appointment"
      />
      <meta name="robots" content="noindex" />
      <script type="application/ld+json">{organizationSchema}</script>
      <script async src={scriptLink} type="text/javascript"></script>
      <link href={cssLink} rel="stylesheet" type="text/css" />
    </>
  )
}

export default MakeAnAppointmentPage
